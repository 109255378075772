require('./bootstrap');
console.log("Bonjour Mr l'inspecteur ! Si jamais tu es en recherche de stage, envoi moi un CV à contact@latoile.dev en me disant que tu as trouvé ce message !");

let vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty('--vh', `${vh}px`);

//HEIGHT FOOTER------------------------------------------------------------------
$('.wrapper').css("margin-bottom", $('footer').height() + "px");
$(window).on('resize', function () {
	$('.wrapper').css("margin-bottom", $('footer').height() + "px");
});

//CLASS ACTIVE NAV -------------------------------------------------------------
$('.navbar-nav a[href="' + location.href + '"]').addClass('active');
$('.navbar-nav a[href="' + location.href + '"]').parents('.mon-dropdown').find(".dropdown-link a.nav-link:first-child").addClass('active');

// DROPDOWN --------------------------------------------------------------------
$('.mon-dropdown .mon-dropdown-toggle').on("click", function () {
	$('.mon-dropdown .mon-dropdown-toggle').toggleClass('active')
	$('.dropdown-div').slideToggle(200);
});

//BURGER NAVBAR ----------------------------------------------------------------
$('#navbar').on('show.bs.collapse', function () {$('.burger').addClass('active');});
$('#navbar').on('hide.bs.collapse', function () {$('.burger').removeClass('active');});

//SMOOTH SCROLL ----------------------------------------------------------------
$('a[href^="#"]').on("click", function () {
	var the_id = $(this).attr("href");
	if (the_id === '#' || the_id === '#quote-carousel') {return;}
	$('html, body').animate({ scrollTop: $(the_id).offset().top - $('nav').height()}, 'slow');
	return false;
});

//TARGET _BLANK ----------------------------------------------------------------
url = $('meta[name=landing]').attr("content");
$('a[href^="http"], a[href^="https"]').not("[href^='" + url + "']").attr("target", "_blank").attr("rel", "noopener dofollow");

//LINK DIV ---------------------------------------------------------------------
$(".div-link").on("click", function () {
	window.location = $(this).data("link");
});

// Particule -------------------------------------------------------------------
require('./particles.js');
particlesJS.load('particles-js', '/js/particles.json');
