try {
	window.$ = window.jQuery = require('jquery');

	require('bootstrap/js/src/alert');
	require('bootstrap/js/src/collapse');
	require('bootstrap/js/src/dropdown');
	require('bootstrap/js/src/carousel');
	require('bootstrap/js/src/modal');
	require('bootstrap/js/src/util');
} catch (e) { }
